/* global filter_js_vars:readonly */

jQuery(function($){

  // Category filtering for template-recipes.php
  function ajaxRecipeFilter() {

    var container = $( '#recipeFilter' );

    container.each( function() {

      var columns = $(this).attr( 'data-columns' );
      var filter = $(this).find( '.js-filter-title' );

      filter.on( 'click', function(e) {

        var id = $(this).attr( 'data-cat-id' );

        if( typeof id !== typeof undefined && id !== false ) {

          e.preventDefault();

          var grid = container.find( '#recipeGallery' );

          $( '#loading' ).show();

          $.ajax({
            url: filter_js_vars.ajaxurl,
            data: {
              action: 'recipe_filter',
              id: id,
              col: columns
            },
            dataType: 'json',
            type: 'POST',
            nonce: filter_js_vars.nonce,
            success: function(response) {
              grid.html( response );
              $( '#loading' ).hide();
            },
            error: function(response) {
              grid.html( response );
              $( '#loading' ).hide();
            },
            cache: false
          });

          $(this).siblings().removeClass( 'filter-current' );
          $(this).addClass( 'filter-current' );

        }

        e.preventDefault();

      });
    });
  }

  ajaxRecipeFilter();

});
